export function sanitizeQueryString(inputString: string): string {

    const charsToBeRemoved = ["<", ">"];
    const charsToBeEscaped = ["+", "-", "=", "&", "|", "!", "(", ")", "{", "}", "[", "]", "^", '"', "~", "*", "?", ":", "/", "\\"];

    return inputString
      .split("").filter(char => !charsToBeRemoved.includes(char)).join("")
      .split("").map(char => charsToBeEscaped.includes(char) ? `\\${char}` : char).join("");
}

export const constructSearchQuery = (x: string): string => {
  return x.split(" ").filter(term => term.length > 0).map(term => `*${term}*`).join(" AND ");
};