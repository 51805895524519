import { Container, Table, apiClient, useLanguageResource } from "@ruter-as/web-components-and-tools";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import formatter from "../../../common/formatter";
import { InvoiceSummary, mapInvoiceSummaries } from "../../../types/freeTicketAgreement/InvoiceSummary";
import GotoColumn from "../../common/Table/GotoColumn/GotoColumn";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import "./FreeTicketInvoiceBasePage.scss";
import { freeTicketInvoiceBaseLanguageResource } from "./lang-resource";
import { sortByMonthAndYearDescendingFn } from "./utils";

const FreeTicketInvoiceBasePage: React.FC = () => {
  const lang = useLanguageResource(freeTicketInvoiceBaseLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const freeTicketService = useValidFreeTicketAgreementService();
  const selectedCompany = authContext.userData.selectedCompany;
  const [loading, setLoading] = useState(true);
  const [invoiceBases, setInvoiceBases] = useState<InvoiceSummary[]>([]);

  const invoiceBasesSortedByDateDesc = [...invoiceBases].sort(sortByMonthAndYearDescendingFn);

  const navigate = useNavigate();

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreement = freeTicketService.invoiceRefs.find((x) => x.agreementNumber === agreementNumberInput);
      return agreement?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketService],
  );

  useEffect(() => {
    const fetch = async () => {
      const response = await apiClient.get(`/freeticket-api/invoice/by-company-id/${selectedCompany?.id}`, mapInvoiceSummaries);
      if (response.type === "success") {
        setInvoiceBases(response.result);
        setLoading(false);
      } else {
        setLoading(() => {
          throw response.error;
        });
      }
    };

    fetch();
  }, [selectedCompany?.id]);

  const formatMonth = (year: number, month: number): string => moment(new Date(year, month - 1, 1)).format("MMM YYYY");

  const renderRow = (invoiceSummary: InvoiceSummary) => (
    <tr onClick={() => navigate(`/fribilletter/fakturagrunnlag/${invoiceSummary.id}`)}>
      <td>{formatMonth(invoiceSummary.year, invoiceSummary.month)}</td>
      <td>{findInvoiceRef(invoiceSummary.agreementNumber)}</td>
      <td style={{ textAlign: "right" }}>{formatter.number.integer(invoiceSummary.ticketCount)}</td>
      <PriceColumn price={invoiceSummary.totalPrice} showDecimals />
      <GotoColumn />
    </tr>
  );

  return (
    <Container width="m" className="components-freeticketagreement-invoicebase" data-test-id="components-freeticketagreement-invoicebase">
      <h1>{lang.title}</h1>
      <Table loading={loading}>
        <thead>
          <tr>
            <th>{lang.month}</th>
            <th>{lang.department}</th>
            <th style={{ textAlign: "right" }}>{lang.ticketCount}</th>
            <th style={{ textAlign: "right" }}>{lang.totalPrice}</th>
            <th className="goto-column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>{invoiceBasesSortedByDateDesc.map(renderRow)}</tbody>
      </Table>
    </Container>
  );
};

export default FreeTicketInvoiceBasePage;
