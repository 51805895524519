interface YearAndMonth {
  year : number,
  month : number
}

export function sortByMonthAndYearDescendingFn(a : YearAndMonth, b : YearAndMonth) {

    if (a.year !== b.year) {
        return b.year - a.year;
    }

    return b.month - a.month;
}