
import { apiClient, Container, formatter, Table, useLanguageResource } from "@ruter-as/web-components-and-tools";
import React, { useEffect, useState } from "react";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import ContactUsLink from "src/components/app/contactUsLink/ContactUsLink";
import { ProductTemplate } from "src/types/ProductTemplate";
import "./AgreementInfo.scss";
import agreementInfoLanguageResource, { AdvantageLanguageResource } from "./lang-resource";

const AgreementInfo: React.FC = () => {
  const language = useLanguageResource(agreementInfoLanguageResource);

  const [productTemplates, setProductTemplates] = useState<ProductTemplate[]>([]);

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await apiClient.request(companyAgreementApi.product.getV2Products());

      if (response.type === "success") {
        setProductTemplates(response.result);
      } else {
        setProductTemplates(() => {
          throw response.error;
        });
      }
    };
    fetchPrices();
  }, []);



  let normal1ZonePrice = 0;
  let normal1ZoneSpecialPrice = 0;
  let normal2ZonePrice = 0;
  let normalAllZonePrice = 0;
  let company1ZonePrice = 0;
  let company1ZoneSpecialPrice = 0;
  let company2ZonePrice = 0;
  let companyAllZonePrice = 0;

  if (productTemplates.length !== 0) {
    const thirtyDays = productTemplates.find(productTemplate => productTemplate.tags.find(x => x === "bigcustomer:subscriptionComparisonTicket"))?.products.filter(x => x.profileId === "ADULT");
    const companyTickes = productTemplates.find(productTemplate => productTemplate.tags.find(x => x === "bigcustomer:subscriptionTicket"))?.products.filter(x => x.profileId === "ADULT");

    if (thirtyDays) {
      normal1ZonePrice = thirtyDays.find(x => x.filters === null)?.prices.find(x => x.nrOfZones === 1)?.priceInclVAT || 0;
      normal1ZoneSpecialPrice = thirtyDays.find(x => x.filters !== null)?.prices.find(x => x.nrOfZones === 1)?.priceInclVAT || 0;
      normal2ZonePrice = thirtyDays.find(x => x.filters === null)?.prices.find((x) => x.nrOfZones === 2)?.priceInclVAT || 0;
      normalAllZonePrice = thirtyDays.find(x => x.filters === null)?.prices.find((x) => x.nrOfZones === 3)?.priceInclVAT || 0;
    }
    if (companyTickes) {
      company1ZonePrice = companyTickes.find(x => x.filters === null)?.prices.find((x) => x.nrOfZones === 1)?.priceInclVAT || 0;
      company1ZoneSpecialPrice = companyTickes.find(x => x.filters !== null)?.prices.find((x) => x.nrOfZones === 1)?.priceInclVAT || 0;
      company2ZonePrice = companyTickes.find(x => x.filters === null)?.prices.find((x) => x.nrOfZones === 2)?.priceInclVAT || 0;
      companyAllZonePrice = companyTickes.find(x => x.filters === null)?.prices.find((x) => x.nrOfZones === 3)?.priceInclVAT || 0;
    }
  }

  const AgreementContains = (item: string, index: number): JSX.Element => (
    <li key={index}>{item}</li>
  );

  const Advantage = (advantage: AdvantageLanguageResource, index: number): JSX.Element => (
    <div key={index}>
      <h3>{advantage.header}</h3>
      <p>{advantage.text}</p>
    </div>
  );


  return (
    <Container width="s" className="components-agreement-info-info">
      <h1>{language.title}</h1>
      <div className="video-container">
        <iframe
          title={language.title}
          src="https://www.youtube.com/embed/qyZeFLZCdPc?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="video"
        />
      </div>
      <p style={{fontStyle: "italic"}}>{language.notice}</p>
      <h2>{language.agreementContainsHeader}</h2>
      <ol>{language.agreementContainsList.map(AgreementContains)}</ol>

      <h2>{language.pricelistHeader}</h2>

      <Table loading={productTemplates.length === 0}>
        <thead>
          <tr>
            <th scope="col">{language.pricelistNumberOfZones}</th>
            <th scope="col">{language.pricelist30Ticket}</th>
            <th scope="col">{language.pricelistMonthTicket}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{language.pricelist1Zone}</td>
            <td>{formatter.number.currency(normal1ZonePrice, false)}</td>
            <td>{formatter.number.currency(Math.floor(company1ZonePrice / 12), false)}</td>
          </tr>
          <tr>
            <td>{language.pricelist1ZoneSpecial}</td>
            <td>{formatter.number.currency(normal1ZoneSpecialPrice, false)}</td>
            <td>{formatter.number.currency(Math.floor(company1ZoneSpecialPrice / 12), false)}</td>
          </tr>
          <tr>
            <td>{language.pricelist2Zones}</td>
            <td>{formatter.number.currency(normal2ZonePrice, false)}</td>
            <td>{formatter.number.currency(Math.floor(company2ZonePrice / 12), false)}</td>
          </tr>
          <tr>
            <td>{language.pricelistAllZones}</td>
            <td>{formatter.number.currency(normalAllZonePrice, false)}</td>
            <td>{formatter.number.currency(Math.floor(companyAllZonePrice / 12), false)}</td>
          </tr>
        </tbody>
      </Table>

      <h2>{language.advantagesHeader}</h2>
      {language.advantages.map(Advantage)}
      <ContactUsLink text={language.callToAction} />
    </Container>
  );
};

export default AgreementInfo;

