import { PaginationResult, usePaginationAndParameters } from "@ruter-as/web-components-and-tools";
import { constructSearchQuery, sanitizeQueryString } from "./processQueryString";

interface PaginationAndQueryResult {
  pagination: PaginationResult
  query: string
  throttledQuery: string
  setQuery: (value: string) => void
  tooFew: boolean
}


const usePaginationAndQuery = ({throttle = 500, minLength = 0, sendRawQueryText = false} = {}): PaginationAndQueryResult => {

  const { pagination, searchParameters: [{ setValue, throttledValue, tooFew, value }] } = usePaginationAndParameters([{ name: "q", minLength : minLength, throttle : throttle }]);

  const sanitizedSearchString = sanitizeQueryString(throttledValue);

  return {
    pagination,
    query: value,
    throttledQuery: sendRawQueryText ? sanitizedSearchString : constructSearchQuery(sanitizedSearchString),
    setQuery: setValue,
    tooFew,
  };
};

export default usePaginationAndQuery;