import { Zone } from "src/common/api/commonTypes/Zone";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend } from "src/common/expiryDate";

interface ChangeZonePostContract {
  zoneFrom: string,
  zoneTo: string,
  expiryDate: Date,
  validAllZones: boolean
}

export const mapChangeZonePostContract = (zoneFrom: string, zoneTo: string, expiryDate: string, zones: Zone[]): ChangeZonePostContract => {
  const validAllZones = !Boolean(zones.find(x => x.name === zoneFrom));

  const contract: ChangeZonePostContract = {
    zoneFrom: zoneFrom,
    zoneTo: zoneTo, 
    expiryDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(expiryDate),
    validAllZones,
  };

  return contract;
};

export default ChangeZonePostContract;

