import { apiClient, ButtonGroup, CheckBoxForminput, Container, DateFormInput, DropdownFormInput, FieldValidationError, formatter, parser, PhoneFormInput, RadioButtonFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidCompanyTicketService } from "src/AuthContext";
import { Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import CreateCompanyTicketFormData, { CreateCompanyTicketErrorType, QuaranteeenError, QuaranteeenErrorJson } from "src/common/api/companyAgreementApi/createTicketFormData";
import CompanyTicketPostContract from "src/common/api/companyAgreementApi/createTicketPostContract";
import { mapCompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { getExpiryDates } from "src/common/expiryDate";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import getTicketOwnerFieldName from "src/common/ticketOwnerFieldName";
import { CancelButton, SubmitButton } from "src/components/common/buttons";
import ProductPrice from "src/components/common/form/ProductPrice";
import { ProductTemplate } from "src/types/ProductTemplate";
import { ProfileId } from "src/types/ProfileId";
import { createTicketLanguageResource } from "./lang-resource";

const CreateTicketform: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const companyTicketService = useValidCompanyTicketService();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const navigate = useNavigate();
  const lang = useLanguageResource(createTicketLanguageResource);
  const ticketOwnerFieldName = getTicketOwnerFieldName(companyTicketService);
  const [zones, setZones] = useState<Zone[]>();
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();
  const [loading, setLoading] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<CreateCompanyTicketErrorType>();
  const dates = companyTicketService.hasIncreasedExpiryDate ? getExpiryDates(32, formLang.today) : getExpiryDates(6, formLang.today);

  useEffect(() => {
    const fetchData = async () => {
      const [zoneResponse, productTemplateResponse] = await Promise.all([
        apiClient.request(companyAgreementApi.zones.getAll()),
        apiClient.request(companyAgreementApi.product.getV2Products()),
      ]);

      if (zoneResponse.type !== "success") {
        setZones(() => {
          throw zoneResponse.error;
        });
        return;
      }

      if (productTemplateResponse.type !== "success") {
        setProductTemplate(() => {
          throw productTemplateResponse.error;
        });
        return;
      }

      setZones(zoneResponse.result);
      setProductTemplate(productTemplateResponse.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
    };

    fetchData();
    return () => { setZones(undefined); };
  }, []);

  const formMethods = useForm<CreateCompanyTicketFormData>({
    defaultValues: {
      employeeNumber: "",
      firstName: "",
      lastName: "",
      agreementNumber: companyTicketService.invoiceRefs[0].agreementNumber || "",
      phone: "",
      startDate: dates[0].value,
      zoneFrom: authContext.features.defaultZones.hasDefaultZone ? authContext.features.defaultZones.fromZone : "",
      zoneTo: authContext.features.defaultZones.hasDefaultZone ? authContext.features.defaultZones.fromZone : "",
      profile: "ADULT",
    },
  });

  if (!zones) {
    return null;
  }

  const mapContract = (formData: CreateCompanyTicketFormData): CompanyTicketPostContract => {
    const phone = parsePhoneNumberFromString(formData.phone);

    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    let zoneFromName = formData.zoneFrom;
    let zoneToName = formData.zoneTo;

    if (companyTicketService.singleZoneHasAdditionalZone) {
      if (zoneToName === zoneFromName) {
        if (zoneFromName === "Kristiansand" || zoneFromName === "Vennesla") {
          zoneFromName = "Kristiansand";
          zoneToName = "Vennesla";
        }
      }
    }

    const backendContract: CompanyTicketPostContract = {
      agreementName: formData.agreementNumber,
      employeeId: formData.employeeNumber,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: phone.nationalNumber.toString(),
      phoneCountryCode: `+${phone.countryCallingCode}`,
      startDate: parser.date.fromShortDateString(formData.startDate).toISOString(),
      zoneFrom: zoneFromName,
      zoneTo: zoneToName,
      addOns: [],
    };

    if (companyTicketService.hasMultipleProfiles) {
      backendContract.profile = formData.profile;
    }

    return backendContract;
  };

  const onSubmit = async (data: CreateCompanyTicketFormData) => {
    setLoading(true);

    const backendContract: CompanyTicketPostContract = mapContract(data);

    const response = await apiClient.request(companyAgreementApi.ticket.create(backendContract));

    if (response.type === "success") {
      navigate("/bedriftsavtale");
    } else if (response.type === "HttpError" && response.responseStatus === 409) {
      const responseBodyJson = response.responseBody as QuaranteeenErrorJson;

      if (responseBodyJson.expectedQuaranteenEndDate && responseBodyJson.lastOrder?.expirationDate) {
        const responseBody: QuaranteeenError = {
          type: "QUARANTEEN_ERROR",
          expectedQuaranteenEndDate: new Date(responseBodyJson.expectedQuaranteenEndDate),
          lastOrder: mapCompanyAgreementOrder(responseBodyJson.lastOrder),
        };

        setBackendError(responseBody);
      } else {
        setBackendError({ type: "EXISTING_OPEN_TICKET_ERROR" });
      }
    } else {
      setLoading(() => {
        throw response.error;
      });
    }
    setLoading(false);
  };

  const getAgreementText = (invoiceReference: string, agreementNumber: string) => {
    if (invoiceReference) {
      return `${invoiceReference} (${agreementNumber})`;
    }
    return `${agreementNumber}`;
  };


  const options = dates.map((o) => ({
    value: o.value,
    text: o.text,
  }));

  const renderErrorMessage = (error: CreateCompanyTicketErrorType) => {
    if (error.type === "QUARANTEEN_ERROR") {
      const formattedLastOrderExpirationDate = error.lastOrder.expirationDate ? formatter.date.toShortDateString(error.lastOrder.expirationDate) : "–";
      const formattedExpectedQuaranteenEndDate = formatter.date.toShortDateString(error.expectedQuaranteenEndDate);
      return <FieldValidationError text={lang.gapToSmall(formattedLastOrderExpirationDate, formattedExpectedQuaranteenEndDate)} />;
    } else {
      return <FieldValidationError text={lang.errorExistingOpenTicket} />;
    }
  };

  const getHumanReadableProfile = (profileId: ProfileId): string | null => {
    switch (profileId) {
      case "ADULT":
        return lang.adult;
      case "YOUNG_ADULT":
        return lang.youngAdult;
      default:
        return null;
    }
  };

  const getProfileOptions = (profileIds: ProfileId[]) => {
    const profileOptions: { text: string, value: ProfileId }[] = [];

    profileIds.forEach(x => {
      const profileText = getHumanReadableProfile(x);
      if (profileText) {
        profileOptions.push({ text: profileText, value: x });
      }
    });

    return profileOptions;
  };

  const renderTerms = () => {
    const { terms } = authContext.features.urls;
    if (!terms) return null;

    const { url } = terms;
    if (terms.type === "External") {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </a>
      );
    } else {
      return (
        <Link to={url} style={{ marginTop: "1.5rem", display: "block" }}>
          {lang.readPolicies}
        </Link>
      );
    }
  };

  const renderDatePicker = () => {

    if (companyTicketService.canSetArbitraryStartupDateForTicket) {
      return (
        <div data-test-id="free-date-picker">
          <DateFormInput minDate={new Date()} name="startDate" required label={formLang.startDate}/>
        </div>
      );
    }

    return <div data-test-id="constrained-date-picker">
        <DropdownFormInput data-test-id="constrained-date-picker" name="startDate" required label={formLang.startDate}>
          {options.map(option => <option value={option.value} key={option.value}>{option.text}</option>)}
        </DropdownFormInput>
      </div>;
  };

  const mappedProfiles = productTemplate?.products.map(product => product.profileId);
  const uniqueProfiles = Array.from(new Set(mappedProfiles));

  const profile = formMethods.watch("profile");

  return (
    <Container width="xs" data-test-id="create-ticket-form-page" className="create-ticket-form-page">
      <h1>{lang.title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PhoneFormInput name="phone" required label={formLang.phone} mobileOnly />
          <TextFormInput name="firstName" required minLength={2} maxLength={50} label={formLang.firstName} />
          <TextFormInput name="lastName" required minLength={2} maxLength={50} label={formLang.lastName} />
          <TextFormInput name="employeeNumber" required minLength={2} maxLength={20} label={ticketOwnerFieldName} />
          <DropdownFormInput name="agreementNumber" label={formLang.invoiceReference} required>
            {companyTicketService.invoiceRefs.map((option) => (
              <option value={option.agreementNumber} key={option.agreementNumber}>{getAgreementText(option.invoiceReference, option.agreementNumber)}</option>
            ))}
          </DropdownFormInput>
          {authContext.features.getZoneInput(zones)}
          {renderDatePicker()}
          {companyTicketService.hasMultipleProfiles && uniqueProfiles.length > 1 &&
            <RadioButtonFormInput name="profile" options={getProfileOptions(uniqueProfiles)} label={lang.profile} />
          }
          <ProductPrice zones={zones} profile={profile} />
          <CheckBoxForminput name="acceptPrivacy" label={lang.acceptPrivay} required />
          {renderTerms()}
          {backendError && renderErrorMessage(backendError)}
          <ButtonGroup>
            <SubmitButton submitting={loading} text={lang.orderTicket} />
            <CancelButton onClick={() => { navigate("/bedriftsavtale"); }} />
          </ButtonGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CreateTicketform;